import React from "react";
import "../../App.css";
import Footer from "../footer/Footer";
import ContactDetails from "../contactdetails/ContactDetails";

function Contact() {
  return (
    <>
      <ContactDetails />
      <Footer />
    </>
  );
}

export default Contact;
