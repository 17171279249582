import React from "react";
import "../../App.css";
import Footer from "../footer/Footer";
import TeamDetails from "../teamdetails/TeamDetails";

function Team() {
  return (
    <>
      <TeamDetails />
      <Footer />
    </>
  );
}

export default Team;
