import React from "react";
import "../../App.css";
import ServiceDetails from "../servicedetails/ServiceDetails";
import Footer from "../footer/Footer";

export default function Services() {
  return (
    <>
      <ServiceDetails />
      <Footer />
    </>
  );
}
