import React from "react";
import "../../App.css";
import Cards from "../cards/Cards";
import Footer from "../footer/Footer";

export default function Products() {
  return (
    <>
      <Cards />
      <Footer />
    </>
  );
}
