import React from "react";
import "../../App.css";
import FaqDetails from "../faqDetails/FaqDetails";
import Footer from "../footer/Footer";

function Team() {
  return (
    <>
      <FaqDetails />
      <Footer />
    </>
  );
}

export default Team;
